.main-content .wizard {
  width: 100%;
  overflow: hidden; }
  .main-content .wizard .content {
    margin-left: 0 !important; }
  .main-content .wizard > .steps {
    position: relative;
    display: block;
    width: 100%; }
    .main-content .wizard > .steps .current-info {
      position: absolute;
      left: -99999px; }
    .main-content .wizard > .steps > ul {
      display: table;
      width: 100%;
      table-layout: fixed;
      margin: 0;
      padding: 0;
      list-style: none; }
      .main-content .wizard > .steps > ul > li {
        display: table-cell;
        width: auto;
        vertical-align: top;
        text-align: center;
        position: relative; }
        .main-content .wizard > .steps > ul > li a {
          position: relative;
          padding-top: 52px;
          margin-top: 20px;
          margin-bottom: 20px;
          display: block; }
        .main-content .wizard > .steps > ul > li:before {
          content: '';
          display: block;
          position: absolute;
          z-index: 9;
          left: 0; }
        .main-content .wizard > .steps > ul > li:after {
          content: '';
          display: block;
          position: absolute;
          z-index: 9;
          right: 0; }
        .main-content .wizard > .steps > ul > li:first-child:before {
          content: none; }
        .main-content .wizard > .steps > ul > li:last-child:after {
          content: none; }
      .main-content .wizard > .steps > ul > li.current > a {
        color: #333333;
        cursor: default; }
      .main-content .wizard > .steps > ul > li.current .step {
        border-color: #00B5B8;
        background-color: #fff;
        color: #00B5B8; }
      .main-content .wizard > .steps > ul > li.disabled a {
        color: #999999;
        cursor: default; }
        .main-content .wizard > .steps > ul > li.disabled a:hover {
          color: #999999;
          cursor: default; }
        .main-content .wizard > .steps > ul > li.disabled a:focus {
          color: #999999;
          cursor: default; }
      .main-content .wizard > .steps > ul > li.done a {
        color: #999999; }
        .main-content .wizard > .steps > ul > li.done a:hover {
          color: #999999; }
        .main-content .wizard > .steps > ul > li.done a:focus {
          color: #999999; }
      .main-content .wizard > .steps > ul > li.done .step {
        background-color: #00B5B8;
        border-color: #00B5B8;
        color: #fff; }
      .main-content .wizard > .steps > ul > li.error .step {
        border-color: #FF7588;
        color: #FF7588; }
    .main-content .wizard > .steps .step {
      background-color: #fff;
      display: inline-block;
      position: absolute;
      top: 0;
      left: 50%;
      margin-left: -24px;
      z-index: 10;
      text-align: center; }
  .main-content .wizard > .content {
    overflow: hidden;
    position: relative;
    width: auto;
    padding: 0;
    margin: 0; }
    .main-content .wizard > .content > .title {
      position: absolute;
      left: -99999px; }
    .main-content .wizard > .content > .body {
      padding: 0 20px; }
    .main-content .wizard > .content > iframe {
      border: 0 none;
      width: 100%;
      height: 100%; }
  .main-content .wizard > .actions {
    position: relative;
    display: block;
    text-align: right;
    padding: 20px;
    padding-top: 0; }
    .main-content .wizard > .actions > ul {
      float: right;
      list-style: none;
      padding: 0;
      margin: 0; }
      .main-content .wizard > .actions > ul:after {
        content: '';
        display: table;
        clear: both; }
      .main-content .wizard > .actions > ul > li {
        float: left; }
        .main-content .wizard > .actions > ul > li + li {
          margin-left: 10px; }
        .main-content .wizard > .actions > ul > li > a {
          background: #00B5B8;
          color: #fff;
          display: block;
          padding: 7px 12px;
          border-radius: 2px;
          border: 1px solid transparent; }
          .main-content .wizard > .actions > ul > li > a:hover {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset; }
          .main-content .wizard > .actions > ul > li > a:focus {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.05) inset; }
          .main-content .wizard > .actions > ul > li > a:active {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.1) inset; }
        .main-content .wizard > .actions > ul > li > a[href="#previous"] {
          background-color: #FF8D60;
          color: #FFF;
          }
          .main-content .wizard > .actions > ul > li > a[href="#previous"]:hover {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset; }
          .main-content .wizard > .actions > ul > li > a[href="#previous"]:focus {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.02) inset; }
          .main-content .wizard > .actions > ul > li > a[href="#previous"]:active {
            -webkit-box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset;
            box-shadow: 0 0 0 100px rgba(0, 0, 0, 0.04) inset; }
      .main-content .wizard > .actions > ul > li.disabled > a {
        color: #FFF; }
        .main-content .wizard > .actions > ul > li.disabled > a:hover {
          color: #FFF; }
        .main-content .wizard > .actions > ul > li.disabled > a:focus {
          color: #FFF; }
      .main-content .wizard > .actions > ul > li.disabled > a[href="#previous"] {
        -webkit-box-shadow: none;
        box-shadow: none; }
        .main-content .wizard > .actions > ul > li.disabled > a[href="#previous"]:hover {
          -webkit-box-shadow: none;
          box-shadow: none; }
        .main-content .wizard > .actions > ul > li.disabled > a[href="#previous"]:focus {
          -webkit-box-shadow: none;
          box-shadow: none; }
  .main-content .wizard.wizard-circle > .steps > ul > li:before, .main-content .wizard.wizard-circle > .steps > ul > li:after {
    top: 43px;
    width: 50%;
    height: 5px;
    background-color: #00B5B8; }
  .main-content .wizard.wizard-circle > .steps > ul > li.current:after {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-circle > .steps > ul > li.current ~ li:before {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-circle > .steps > ul > li.current ~ li:after {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-circle > .steps .step {
    width: 50px;
    height: 50px;
    line-height: 40px;
    border: 5px solid #F5F7FA;
    font-size: 1.3rem;
    border-radius: 50%; }
  .main-content .wizard.wizard-notification > .steps > ul > li:before, .main-content .wizard.wizard-notification > .steps > ul > li:after {
    top: 39px;
    width: 50%;
    height: 2px;
    background-color: #00B5B8; }
  .main-content .wizard.wizard-notification > .steps > ul > li.current .step {
    border: 2px solid #00B5B8;
    color: #00B5B8;
    line-height: 36px; }
    .main-content .wizard.wizard-notification > .steps > ul > li.current .step:after {
      border-top-color: #00B5B8; }
  .main-content .wizard.wizard-notification > .steps > ul > li.current:after {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-notification > .steps > ul > li.current ~ li:before {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-notification > .steps > ul > li.current ~ li:after {
    background-color: #F5F7FA; }
  .main-content .wizard.wizard-notification > .steps > ul > li.done .step {
    color: #FFF; }
    .main-content .wizard.wizard-notification > .steps > ul > li.done .step:after {
      border-top-color: #00B5B8; }
  .main-content .wizard.wizard-notification > .steps .step {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 1.3rem;
    border-radius: 15%;
    background-color: #F5F7FA; }
    .main-content .wizard.wizard-notification > .steps .step:after {
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -8px;
      margin-bottom: -8px;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-top: 8px solid #F5F7FA; }
  .main-content .wizard.vertical > .steps {
    display: inline;
    float: left;
    width: 10%; }
    .main-content .wizard.vertical > .steps > ul > li {
      display: block;
      width: 100%; }
      .main-content .wizard.vertical > .steps > ul > li:before, .main-content .wizard.vertical > .steps > ul > li:after {
        background-color: transparent; }
    .main-content .wizard.vertical > .steps > ul > li.current:before, .main-content .wizard.vertical > .steps > ul > li.current:after {
      background-color: transparent; }
    .main-content .wizard.vertical > .steps > ul > li.current ~ li:before {
      background-color: transparent; }
    .main-content .wizard.vertical > .steps > ul > li.current ~ li:after {
      background-color: transparent; }

@media (max-width: 768px) {
  .main-content .wizard > .steps > ul {
    margin-bottom: 20px; }
    .main-content .wizard > .steps > ul > li {
      display: block;
      float: left;
      width: 50%; }
      .main-content .wizard > .steps > ul > li > a {
        margin-bottom: 0; }
      .main-content .wizard > .steps > ul > li:first-child:before {
        content: ''; }
      .main-content .wizard > .steps > ul > li:last-child:after {
        content: '';
        background-color: #00B5B8; }
  .main-content .wizard.vertical > .steps {
    width: 15%; } }

@media (max-width: 480px) {
  .main-content .wizard > .steps > ul > li {
    width: 100%; }
  .main-content .wizard > .steps > ul > li.current:after {
    background-color: #00B5B8; }
  .main-content .wizard.vertical > .steps {
    width: 20%; } }
