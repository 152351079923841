@import "../core/variables/variables";
@import "../core/variables/app-variables";

// close icon in sweet alert of HTML
.swal2-container.swal2-center.swal2-fade.swal2-shown {
  .swal2-popup.swal2-modal.swal2-show {
    .swal2-header {
      button.swal2-close:focus {
        outline: none;
      }
    }

    .swal2-actions {
      button.btn:focus {
        box-shadow: none;
      }
    }
  }
}

// Sweet Alert
.swal2-modal {
  .swal2-confirm.btn-success {
    background-color: $success !important;
  }

  .swal2-cancel.btn-danger {
    background-color: $danger !important;
  }
}
