
.resize-n{
    resize: none
}


.c-alias {cursor: alias;}
.c-all-scroll {cursor: all-scroll;}
.c-auto {cursor: auto;}
.c-cell {cursor: cell;}
.c-context-menu {cursor: context-menu;}
.c-col-resize {cursor: col-resize;}
.c-copy {cursor: copy;}
.c-crosshair {cursor: crosshair;}
.c-default {cursor: default;}
.c-e-resize {cursor: e-resize;}
.c-ew-resize {cursor: ew-resize;}
.c-grab {cursor: -webkit-grab; cursor: grab;}
.c-grabbing {cursor: -webkit-grabbing; cursor: grabbing;}
.c-help {cursor: help;}
.c-move {cursor: move;}
.c-n-resize {cursor: n-resize;}
.c-ne-resize {cursor: ne-resize;}
.c-nesw-resize {cursor: nesw-resize;}
.c-ns-resize {cursor: ns-resize;}
.c-nw-resize {cursor: nw-resize;}
.c-nwse-resize {cursor: nwse-resize;}
.c-no-drop {cursor: no-drop;}
.c-none {cursor: none;}
.c-not-allowed {cursor: not-allowed;}
.c-pointer {cursor: pointer;}
.c-progress {cursor: progress;}
.c-row-resize {cursor: row-resize;}
.c-s-resize {cursor: s-resize;}
.c-se-resize {cursor: se-resize;}
.c-sw-resize {cursor: sw-resize;}
.c-text {cursor: text;}
.c-w-resize {cursor: w-resize;}
.c-wait {cursor: wait;}
.c-zoom-in {cursor: zoom-in;}
.c-zoom-out {cursor: zoom-out;}

.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}


.pos-rlt{position: relative;}
.pos-stc{position: static !important;}
.pos-abt{position: absolute;}
.pos-fix{position: fixed;}

.show{visibility: visible;}
.line {width: 100%;height: 2px;margin: 10px 0;font-size:0;overflow: hidden;}
.line-xs{margin: 0}
.line-lg{margin-top:15px;margin-bottom: 15px}
.line-dashed{border-style: dashed !important;background-color: transparent;border-width:0;}
.no-line{border-width: 0}
.no-border, .no-borders{border-color:transparent;border-width:0}
.no-radius{border-radius: 0}
.block{display:block;}
.block.hide{display: none;}
.inline{display:inline-block !important;}
.flex{display:flex!important};
// .none{display: none;}
.pull-none{float: none;}
.rounded{border-radius: 500px;}
.v-a-t{vertical-align: top;}
.clear{display:block;overflow: hidden;}
.no-bg{background-color: transparent; color: inherit;}
.no-select{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.subline{
  text-decoration: underline;
}

.l-h-0x{line-height: 0;}
.l-h-1x{line-height: 1.2;}
.l-h-2x{line-height: 2em;}

.l-s-1x{letter-spacing: 1}
.l-s-2x{letter-spacing: 2}
.l-s-3x{letter-spacing: 3}

.font-normal{font-weight: normal;}
.font-thin{font-weight: 300;}
.font-bold{font-weight: 700;}

.text-ellipsis{
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}

.cursor-pointer{
  cursor: pointer;
}

.center{text-align: center;}
.left{text-align: left;}
.right{text-align: right;}
.text-u-c{text-transform: uppercase !important;}
.text-c{text-transform: capitalize;}
.text-l-t{text-decoration: line-through;}
.text-u-l{text-decoration: underline;}
.text-active, .active > .text, .active > .auto .text{display: none !important;}
.active > .text-active, .active > .auto .text-active{display: inline-block !important;}
.box-shadow{
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}
.box-shadow-lg{
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.05);
}

.no-shadow{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.wrapper-xs{padding: 5px;}
.wrapper-sm{padding: 10px !important;}
.wrapper{padding: 15px  !important;}
.wrapper-md{padding: 20px;}
.wrapper-lg{padding: 30px;}
.wrapper-xl{padding: 50px;}
.padder-lg{padding-left:30px;padding-right: 30px}
.padder-md{padding-left:20px;padding-right: 20px}
.padder-sm{padding-left:10px;padding-right: 10px}
.padder{padding-left:15px;padding-right: 15px}
.padder-v{padding-top:15px;padding-bottom: 15px}
.no-padder{padding: 0 !important;}
.pull-in{margin-left: -15px;margin-right: -15px;}
.pull-out{margin:-10px -15px;}


.b-l-2x{border-left-width: 2px}
.b-l-3x{border-left-width: 3px}
.b-l-4x{border-left-width: 4px}
.b-l-5x{border-left-width: 5px}

.b-2x{border-width: 2px}
.b-3x{border-width: 3px}
.b-4x{border-width: 4px}
.b-5x{border-width: 5px}

.f-0{font-size:0px !important}
.f-1{font-size:1px !important}
.f-2{font-size:2px !important}
.f-3{font-size:3px !important}
.f-4{font-size:4px !important}
.f-5{font-size:5px !important}
.f-6{font-size:6px !important}
.f-7{font-size:7px !important}
.f-8{font-size:8px !important}
.f-9{font-size:9px !important}
.f-10{font-size:10px !important}
.f-11{font-size:11px !important}
.f-12{font-size:12px !important}
.f-13{font-size:13px !important}
.f-14{font-size:14px !important}
.f-15{font-size:15px !important}
.f-16{font-size:16px !important}
.f-17{font-size:17px !important}
.f-18{font-size:18px !important}
.f-19{font-size:19px !important}
.f-20{font-size:20px !important}
.f-21{font-size:21px !important}
.f-22{font-size:22px !important}
.f-23{font-size:23px !important}
.f-24{font-size:24px !important}
.f-25{font-size:25px !important}
.f-26{font-size:26px !important}
.f-27{font-size:27px !important}
.f-28{font-size:28px !important}
.f-29{font-size:29px !important}
.f-30{font-size:30px !important}
.f-31{font-size:31px !important}
.f-32{font-size:32px !important}
.f-33{font-size:33px !important}
.f-34{font-size:34px !important}
.f-35{font-size:35px !important}
.f-36{font-size:36px !important}
.f-37{font-size:37px !important}
.f-38{font-size:38px !important}
.f-39{font-size:39px !important}
.f-40{font-size:40px !important}
.f-41{font-size:41px !important}
.f-42{font-size:42px !important}
.f-43{font-size:43px !important}
.f-44{font-size:44px !important}
.f-45{font-size:45px !important}
.f-46{font-size:46px !important}
.f-47{font-size:47px !important}
.f-48{font-size:48px !important}
.f-49{font-size:49px !important}
.f-50{font-size:50px !important}
.f-51{font-size:51px !important}
.f-52{font-size:52px !important}
.f-53{font-size:53px !important}
.f-54{font-size:54px !important}
.f-55{font-size:55px !important}
.f-56{font-size:56px !important}
.f-57{font-size:57px !important}
.f-58{font-size:58px !important}
.f-59{font-size:59px !important}

.f-80{font-size:80px !important}
.f-140{font-size:140px !important}



.op-1{opacity:1}
.op-09{opacity:0.9}
.op-08{opacity:0.8}
.op-07{opacity:0.7}
.op-06{opacity:0.6}
.op-05{opacity:0.5}
.op-04{opacity:0.4}
.op-03{opacity:0.3}
.op-02{opacity:0.2}
.op-01{opacity:0.1}

.m-auto{margin : auto;}

.m-xxs{margin: 2px 4px}
.m-xs{margin: 5px;}
.m-sm{margin: 10px;}
.m{margin: 15px;}
.m-md{margin: 20px;}
.m-lg{margin: 30px;}
.m-xl{margin: 50px;}
.m-n{margin: 0 !important}

.m-l-none{margin-left: 0 !important}
.m-l-xs{margin-left: 5px;}
.m-l-sm{margin-left: 10px;}
.m-l{margin-left: 15px}
.m-l-md{margin-left: 20px;}
.m-l-lg{margin-left: 30px;}
.m-l-xl{margin-left: 40px;}
.m-l-xxl{margin-left: 50px;}

.m-l-n-xxs{margin-left: -1px}
.m-l-n-xs{margin-left: -5px}
.m-l-n-sm{margin-left: -10px}
.m-l-n{margin-left: -15px}
.m-l-n-md{margin-left: -20px}
.m-l-n-lg{margin-left: -30px}
.m-l-n-xl{margin-left: -40px}
.m-l-n-xxl{margin-left: -50px}

.m-t-none{margin-top:0 !important}
.m-t-xxs{margin-top: 1px;}
.m-t-xs{margin-top: 5px;}
.m-t-sm{margin-top: 10px;}
.m-t{margin-top: 15px}
.m-t-md{margin-top: 20px;}
.m-t-lg{margin-top: 30px;}
.m-t-xl{margin-top: 40px;}
.m-t-xxl{margin-top: 50px;}
.m-t-75{margin-top: 75px;}
.m-t-88{margin-top: 88px;}
.m-t-100{margin-top: 100px;}
.m-t-200{margin-top: 200px;}

.m-t-0{margin-top:0px}
.m-t-1{margin-top:1px}
.m-t-2{margin-top:2px}
.m-t-3{margin-top:3px}
.m-t-4{margin-top:4px}
.m-t-5{margin-top:5px}
.m-t-6{margin-top:6px}
.m-t-7{margin-top:7px}
.m-t-8{margin-top:8px}
.m-t-9{margin-top:9px}
.m-t-10{margin-top:10px}
.m-t-11{margin-top:11px}
.m-t-12{margin-top:12px}
.m-t-13{margin-top:13px}
.m-t-14{margin-top:14px}
.m-t-15{margin-top:15px}
.m-t-16{margin-top:16px}
.m-t-17{margin-top:17px}
.m-t-18{margin-top:18px}
.m-t-19{margin-top:19px}
.m-t-20{margin-top:20px}
.m-t-21{margin-top:21px}
.m-t-22{margin-top:22px}
.m-t-23{margin-top:23px}
.m-t-24{margin-top:24px}
.m-t-25{margin-top:25px}
.m-t-26{margin-top:26px}
.m-t-27{margin-top:27px}
.m-t-28{margin-top:28px}
.m-t-29{margin-top:29px}
.m-t-30{margin-top:30px}
.m-t-31{margin-top:31px}
.m-t-32{margin-top:32px}
.m-t-33{margin-top:33px}
.m-t-34{margin-top:34px}
.m-t-35{margin-top:35px}
.m-t-36{margin-top:36px}
.m-t-37{margin-top:37px}
.m-t-38{margin-top:38px}
.m-t-39{margin-top:39px}
.m-t-40{margin-top:40px}
.m-t-43{margin-top:43px}
.m-t-50{margin-top:50px}
.m-t-55{margin-top:55px}
.m-t-56{margin-top:56px}
.m-t-57{margin-top:57px}
.m-t-58{margin-top:58px}
.m-t-59{margin-top:59px}
.m-t-60{margin-top:60px}
.m-t-70{margin-top:70px}
.m-t-80{margin-top:80px}

.m-t-n-xxs{margin-top: -1px}
.m-t-n-xs{margin-top: -5px}
.m-t-n-sm{margin-top: -10px}
.m-t-n{margin-top: -15px}
.m-t-n-md{margin-top: -20px}
.m-t-n-lg{margin-top: -30px}
.m-t-n-xl{margin-top: -40px}
.m-t-n-xxl{margin-top: -50px}

.t-n-17{top:-17px;}

.m-r-none{margin-right: 0 !important}
.m-r-xxs{margin-right: 1px}
.m-r-xs{margin-right: 5px}
.m-r-sm{margin-right: 10px}
.m-r{margin-right: 15px}
.m-r-md{margin-right: 20px}
.m-r-lg{margin-right: 30px}
.m-r-xl{margin-right: 40px}
.m-r-xxl{margin-right: 50px}

.m-r-n-xxs{margin-right: -1px}
.m-r-n-xs{margin-right: -5px}
.m-r-n-sm{margin-right: -10px}
.m-r-n{margin-right: -15px}
.m-r-n-md{margin-right: -20px}
.m-r-n-lg{margin-right: -30px}
.m-r-n-xl{margin-right: -40px}
.m-r-n-xxl{margin-right: -50px}

.m-b-none{margin-bottom: 0 !important}
.m-b-xxs{margin-bottom: 1px;}
.m-b-xs{margin-bottom: 5px;}
.m-b-sm{margin-bottom: 10px;}
.m-b{margin-bottom: 15px;}
.m-b-md{margin-bottom: 20px;}
.m-b-lg{margin-bottom: 30px;}
.m-b-xl{margin-bottom: 40px;}
.m-b-xxl{margin-bottom: 50px;}
.m-b-80{margin-bottom: 80px;}

.m-b-n-xxs{margin-bottom: -1px}
.m-b-n-xs{margin-bottom: -5px}
.m-b-n-sm{margin-bottom: -10px}
.m-b-n{margin-bottom: -15px}
.m-b-n-md{margin-bottom: -20px}
.m-b-n-lg{margin-bottom: -30px}
.m-b-n-xl{margin-bottom: -40px}
.m-b-n-xxl{margin-bottom: -50px}



.p-xxs{padding: 2px 4px}
.p-xs{padding: 5px;}
.p-sm{padding: 10px;}
.p{padding: 15px;}
.p-md{padding: 20px;}
.p-lg{padding: 30px;}
.p-xl{padding: 50px;}
.p-n{padding: 0 !important}

.p-l-none{padding-left: 0 !important}
.p-l-xs{padding-left: 5px;}
.p-l-sm{padding-left: 10px;}
.p-l{padding-left: 15px}
.p-l-md{padding-left: 20px;}
.p-l-lg{padding-left: 30px;}
.p-l-xl{padding-left: 40px;}
.p-l-xxl{padding-left: 50px;}

.p-t-none{padding-top:0 !important}
.p-t-xxs{padding-top: 1px;}
.p-t-xs{padding-top: 5px;}
.p-t-sm{padding-top: 10px;}
.p-t{padding-top: 15px}
.p-t-md{padding-top: 20px;}
.p-t-lg{padding-top: 30px;}
.p-t-xl{padding-top: 40px;}
.p-t-xxl{padding-top: 50px;}
.p-t-75{padding-top: 75px;}
.p-t-88{padding-top: 88px;}
.p-t-100{padding-top: 100px;}
.p-t-200{padding-top: 200px;}

.p-r-none{padding-right: 0 !important}
.p-r-xxs{padding-right: 1px}
.p-r-xs{padding-right: 5px}
.p-r-sm{padding-right: 10px}
.p-r{padding-right: 15px}
.p-r-md{padding-right: 20px}
.p-r-lg{padding-right: 30px}
.p-r-xl{padding-right: 40px}
.p-r-xxl{padding-right: 50px}

.p-b-none{padding-bottom: 0 !important}
.p-b-xxs{padding-bottom: 1px;}
.p-b-xs{padding-bottom: 5px;}
.p-b-sm{padding-bottom: 10px;}
.p-b{padding-bottom: 15px;}
.p-b-md{padding-bottom: 20px;}
.p-b-lg{padding-bottom: 30px;}
.p-b-xl{padding-bottom: 40px;}
.p-b-xxl{padding-bottom: 50px;}

.t-20{
  top : 20px;
}

.w-5{
  width: 5%;
}


.w-10{
  width: 10%;
}

.w-20{
  width: 20%;
}

.w-25{
  width: 25%;
}

.w-30{
  width: 30%;
}

.w-32{
  width: 32%;
}

.w-38-8 {
    width: 38.8%;
}

.w-39{
    width: 39%;
}
.w-40 {
    width: 40%;
}

.w-45 {
    width: 45%;
}

.w-49 {
    width: 49%;
}
.w-50{
  width: 50%;
}

.w-60{
  width: 66%;
}


.w-66{
  width: 66%;
}

.w-70{
  width: 70%;
}


.w-75{
  width: 75%;
}

.w-80{
  width: 80%;
}

.w-80v{
  width: 80vw;
}

.w-90{
  width: 90%;
}

.w-100{
  width: 100%;
}


.w-1x{
  width: 1em;
}
.w-2x{
  width: 2em;
}
.w-3x{
  width: 3em;
}
.w-xxs{
  width: 60px;
}
.w-xs{
  width: 90px;
}
.w-sm{
  width: 150px;
}
.w{
  width: 200px;
}
.w-md{
  width: 240px;
}
.w-lg{
  width: 280px;
}
.w-xl{
  width: 320px;
}
.w-xxl{
  width: 360px;
}
.w-full{
  width: 100%;
}
.w-auto{
  width: auto;
}
.h-auto{
  height: auto;
}
.h-full{
  height: 100%;
}

.h-20{
  height: 20px;
}
.h-25{
  height: 25px;
}
.h-35{
  height: 35px;
}
.h-60{
  height: 60px;
}
.h-70{
  height: 70px;
}

.h-100{
  height: 100px;
}
.h-200{
  height: 200px;
}
.h-300{
  height: 300px;
}

.thumb-xl{width: 128px;display: inline-block}
.thumb-lg{width: 96px;display: inline-block}
.thumb-md{width: 64px;display: inline-block}
.thumb{width: 50px;display: inline-block}
.thumb-sm{width: 40px;display: inline-block}
.thumb-xs{width: 34px;display: inline-block}
.thumb-xxs{width: 30px;display: inline-block}
.thumb, 
.thumb-xs, 
.thumb-sm, 
.thumb-md, 
.thumb-lg,
.thumb-btn{
  img{
    height: auto;
    max-width: 100%;
    vertical-align: middle;
  }
}
.img-full{
  width: 100%;
  img{
    width: 100%;
  }
}

.scrollable{
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  &.hover {
    overflow-y: hidden !important;
    &:hover
    {
      overflow: visible !important;
      overflow-y: auto !important;
    }
  }

  .smart & {
    overflow-y: auto !important;
  }

}

.scroll-x, .scroll-y{overflow:hidden;-webkit-overflow-scrolling:touch;}
.scroll-y{overflow-y:auto;}
.scroll-x{overflow-x:auto;}

.backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  &.fade{
    opacity: 0;
    filter: alpha(opacity=0);
  }
  &.in{
    opacity: 0.8;
    filter: alpha(opacity=80);
  }
}

/*desktop*/
@media screen and (min-width: 992px) {
  .col-lg-2-4{width: 20.000%;float: left;}
}

// sm
@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm.show{display: inherit !important;}
  .no-m-sm{margin:0 !important;}
}

/*phone*/
@media (max-width: 767px) {
  .w-auto-xs{width: auto;}
  .shift{display: none !important;}
  .shift.in{display: block !important;}
  .row-2 [class*="col"]{width: 50%;float: left}
  .row-2 .col-0{clear: none}
  .row-2 li:nth-child(odd) { clear: left;margin-left: 0}
  .text-center-xs{text-align: center;}
  .text-left-xs{text-align: left;}
  .text-right-xs{text-align: right;}
  .no-border-xs{border-width: 0;}
  .pull-none-xs{float: none !important;}
  .pull-right-xs{float: right !important;}
  .pull-left-xs{float: left !important;}
  .dropdown-menu.pull-none-xs{left: 0;}
  .hidden-xs.show{display: inherit !important;}
  // .wrapper-lg, .wrapper-md{padding: 15px;}
  // .padder-lg, .padder-md{padding-left: 15px;padding-right: 15px;}
  .no-m-xs{margin:0 !important;}
}


