$main-color: #00d9d3;
$secondary-color: #0000ff;

// .button{
//     background: $main-color;
//     color: white !important;
// }

.button:hover{
    opacity: 0.85;
}

.form-control:focus{
    border-color: $secondary-color !important;
}

.form-control.is-invalid{
    border-color: #F55252 !important;
}

form > div.d-inline-block{
    vertical-align: top;
}


.list-group-item.active{
    background-color: #2f8be6 !important;
    border-color: #2f8be6 !important;
}


.list-group-item:hover{
    background-color: #a0a0a0;
    border-color: #a0a0a0;
}

ul > .list-group-item{
    background: #e5e5e5;

    .badge-primary{
        color: #2f8be6;
        background-color: transparent;
    }
}

ul > .list-group-item.active{
    .badge-primary{
        color: #e5e5e5 !important;
    }
}


//CSS FOR UPLOAD A IMAGE INPUT, USED FOR EXAMPLE UPLOAD IMAGE IN CATEGORY OR PRODUCT, ETC
.model-image{
    width: 220px;
    height: 150px; 
    margin-bottom: 5px;
}

.model-image .block-buttons{
    position: absolute; 
    bottom: 0px; 
    width: 220px;
}

.model-image img{
    width: 220px; 
    height: 151px; 
    border: 1px solid #9a9aa6;
    border-radius: 6px;
    padding-top: 1px;
    object-fit: contain;
}

.model-image .block-buttons .buttons{
    width: 100%; 
    background: rgba(27, 83, 123, 0.6);
    border-radius: 0px 0px 5px 5px;
    color: black; 
    display: block;
    overflow: auto;
}

.model-image .block-buttons .buttons i{
    position: absolute; 
    top: 5px;
}

.model-upload {
    object-fit: contain;
}

.model-image-btn{
    position: relative;
    top: 10px;
    left: 180px;
}

.model-image-btn button{
    transform: scale(0.8);
}

.model-image-btn i{
    color: white;
    font-size: 15px;
}

.loader-image-frame{
    width: 70px;
    height: 70px;
    margin: auto;
    position: relative;
    text-align: center;
    right: 0px;
}

.loader-text{
    font-family: mont-3 !important;
    font-size: 18px;
    color: $main-color;
}

.loader-image-frame .loader {
    position: absolute;
    border: 5px solid transparent;
    border-radius: 50%;
    width: 70px; 
    height: 70px;
    border-left: 5px solid #002b49;
    border-right: 5px solid #002b49;
    top: 5px; left: 5px;
    animation : anticlockwisespin 1s linear infinite;
}

@keyframes anticlockwisespin{
    from {transform: rotate(0deg);}
    to {transform: rotate(-360deg);}
}


////////// Animation for lazyload
@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    }
}

.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all .5s ease-in-out;
  background: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999999;

  .loaded > & {
    opacity: 0;
    visibility: hidden;
  }
}

.bounce-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  margin: -9px 0 0 -35px;
  transition: all .2s;
  text-align: center;
  z-index: 10000;

  .bounce1,
  .bounce2,
  .bounce3 {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 100%;
    background-color: #CCC;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, .15);
    -webkit-animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
    animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
  }

  .bounce1 {
    -webkit-animation-delay: -.32s;
    animation-delay: -.32s;
  }

  .bounce2 {
    -webkit-animation-delay: -.16s;
    animation-delay: -.16s;
  }
}


#search{
    border: none;
    border-bottom: 1px solid #e1e1e1;
    border-radius: 0px;
}

.blockPublicationDetail .carousel-control-prev-icon{
    background-color: #191828;
    padding: 20px;
    border-radius: 25px;
    background-size: 27px;
    background-position-x: 5px;
}

.blockPublicationDetail .carousel-control-next-icon, .carousel-control-next-icon{
    background-color: #191828;
    padding: 20px;
    border-radius: 25px;
    background-size: 27px;
    background-position-x: 8px;
}

.blockPublicationDetail .carousel-inner > .carousel-item > img{
    min-height: 300px !important;
    object-fit: contain !important;
}