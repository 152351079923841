@import "../core/colors/palette-variables";

.cd-horizontal-timeline {
  opacity: 0;
  margin: 2em auto;
  transition: opacity 0.2s;
  &::before {
    // never visible - this is used in jQuery to check the current MQ
    content: "mobile";
    display: none;
  }

  &.loaded {
    // show the timeline after events position has been set (using JavaScript)
    opacity: 1;
  }

  .timeline {
    position: relative;
    height: 100px;
    width: 90%;
    margin: 0 auto;
    &:before,
    &:after {
      display: none;
    }
    ul,
    ol {
      li {
        list-style: none;
      }
    }

    .events-wrapper {
      position: relative;
      height: 100%;
      margin: 0 40px;
      overflow: hidden;
      .events {
        // this is the primary lighten-4 line timeline
        position: absolute;
        z-index: 1;
        left: 0;
        top: 49px;
        height: 2px;
        // width will be set using JavaScript
        background: map-get($primary-color, lighten-4);
        transition: transform 0.4s;
        .filling-line {
          // this is used to create the green line filling the timeline
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background-color: theme-color(primary);
          transform: scale(0);
          transform-origin: left center;
          transition: transform 0.3s;
        }
      }
    }
  }


  .events a {
    position: absolute;
    bottom: 0;
    z-index: 2;
    text-align: center;
    padding-bottom: 15px;
    color: $body-color;
    &::after {
      // this is used to create the event spot/circle
      content: "";
      position: absolute;
      @include center(x);
      bottom: -5px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 2px solid map-get($primary-color, lighten-4);
      background-color: $white;
      transition: background-color 0.3s, border-color 0.3s;
    }
    &:hover::after {
      background-color: theme-color(primary) !important;
      border-color: theme-color(primary);
    }

    &.selected {
      pointer-events: none;
      &::after {
        background-color: theme-color(primary);
        border-color: theme-color(primary);
      }
    }

    &.older-event::after {
      border-color: theme-color(primary);
    }
  }
  @media only screen and (min-width: 1100px) {
    margin: 4rem auto;
    &::before {
      content: "desktop";
    }
  }

  .events-content {
    position: relative;
    width: 100%;
    margin: 2rem 0;
    overflow: hidden;
    transition: height 0.4s;

    li {
      list-style: none;
      position: absolute;
      z-index: 1;
      width: 100%;
      left: 0;
      top: 0;
      transform: translateX(-100%);
      transition: all 0.25s ease;
      padding: 0 5%;
      opacity: 0;
      animation-duration: 0.4s;
      animation-timing-function: ease-in-out;

      &.selected {
        // visible event content
        position: relative;
        z-index: 2;
        opacity: 1;
        transform: translateX(0);
      }
      &.enter-right,
      &.leave-right {
        animation-name: cd-enter-right;
      }
      &.enter-left,
      &.leave-left {
        animation-name: cd-enter-left;
      }
      &.leave-right,
      &.leave-left {
        animation-direction: reverse;
      }
    }

    li > * {
      margin: 0 auto;
    }
    p {
      line-height: 1.6;
    }
  }
}

.cd-timeline-navigation a {
  // these are the left/right arrows to navigate the timeline
  position: absolute;
  z-index: 1;
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid map-get($primary-color, lighten-4);
  background-color: $white;
  // replace text with an icon
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
  transition: border-color 0.3s;
  &::after {
    // arrow icon
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    @include center;
    background: url(../../img/svg/cd-arrow.svg) no-repeat 0 0;
  }
  &.prev {
    top: 60%;
    left: 25px;
    transform: translateY(-50%) rotate(180deg);
  }
  &.next {
    top: 43%;
    right: 8px;
  }
  &:hover {
    border-color: theme-color(primary);
  }
  &.inactive {
    cursor: not-allowed;
    &::after {
      background-position: 0 -16px;
    }
    &:hover {
      border-color: map-get($primary-color, lighten-4);
    }
  }
}


@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
